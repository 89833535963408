import {
    IConfigurationForm,
    IConfigurationFormCategory,
    IConfigurationFormProduct,
    IConfigurationFormSearchAttribute,
    IConfigurationFormSearchForm,
    IConfigurationFormSlot,
    IDataConfigurationForm,
    IDataConfigurationFormCategory,
    IDataConfigurationFormProduct,
    IDataConfigurationFormSearchAttribute,
    IDataConfigurationFormSearchForm,
    IDataConfigurationFormSlot,
    IDataFormField,
    IFormField,
} from '../../types';
import { convertValidation } from '../forms';

const convertProduct = (product: IDataConfigurationFormProduct) => {
    const { pricelist_id, count, count_in_stock, is_recommended, only_for_presale, name_html, ...rest } = product;
    return {
        ...rest,
        nameHtml: name_html,
        articul: pricelist_id,
        amountInStock: count_in_stock ?? 0,
        amountRequired: count,
        recommended: is_recommended,
        onlyForPresale: only_for_presale,
    } as IConfigurationFormProduct;
};

const convertSlots = (slots: IDataConfigurationFormSlot[]) =>
    slots.map(slot => {
        const { name, selected_item, additional_items, ...rest } = slot;
        const [type, index] = name.split(':');

        return {
            name,
            type,
            index: Number(index),
            selectedItem: selected_item ? convertProduct(selected_item) : null,
            additionalItems: additional_items ? additional_items.map(ai => convertProduct(ai)) : null,
            amount: 1,
            ...rest,
        } as IConfigurationFormSlot;
    });

const convertCategories = (categories: IDataConfigurationFormCategory[]) =>
    categories.map(cat => {
        const {
            name,
            display_name,
            independent_slots,
            slots,
            available_items,
            available_amount,
            show_more_button,
            show_select_amount,
            show_count,
            show_count_additional_items,
            hide_price,
            hide_price_additional_items,
            is_disabled,
            search_available,
            search_regex,
            virtual_item_id,
            ...rest
        } = cat;

        return {
            name,
            title: display_name,
            independentSlots: independent_slots,
            slots: convertSlots(slots),
            availableItems: available_items ? available_items.map(i => convertProduct(i)) : null,
            availableAmount: available_amount,
            showSelectAmount: show_select_amount,
            showButtonMore: show_more_button,
            showAmountInStock: show_count,
            showAmountInStockAdditionalItems: show_count_additional_items,
            hidePrice: hide_price,
            hidePriceAdditionalItems: hide_price_additional_items,
            disabled: is_disabled,
            searchAvailable: search_available,
            searchRegex: search_regex,
            virtualItemId: virtual_item_id,
            ...rest,
        } as IConfigurationFormCategory;
    });

const convertFields = (fields: IDataFormField[] = []) =>
    fields.map(field => ({ ...field, validation: convertValidation(field.validation) } as IFormField));

const convertConfigurationForm = ({ categories, count, app_id, fields, parent_id, parent_naming, ...rest }: IDataConfigurationForm) =>
    ({
        ...rest,
        appId: app_id,
        amount: count,
        categories: convertCategories(categories),
        fields: convertFields(fields),
        parentId: parent_id,
        parentNaming: parent_naming,
    } as IConfigurationForm);

const convertConfigurationFormSearchAttributes = (searchAttributes: IDataConfigurationFormSearchAttribute[]) =>
    searchAttributes.map(
        ({ display_name, is_required, ...rest }) => ({ title: display_name, required: is_required, ...rest } as IConfigurationFormSearchAttribute)
    );

const convertConfigurationFormSearchForm = ({ available_action, attributes, items, virtual_item_price, ...rest }: IDataConfigurationFormSearchForm) =>
    ({
        actionAvailable: available_action,
        attributes: convertConfigurationFormSearchAttributes(attributes),
        items: (items ?? []).map(i => convertProduct(i)),
        virtualItemPrice: virtual_item_price,
    } as IConfigurationFormSearchForm);

export default convertConfigurationForm;
export {
    convertCategories,
    convertSlots,
    convertProduct,
    convertFields,
    convertConfigurationFormSearchForm,
    convertConfigurationFormSearchAttributes,
};
