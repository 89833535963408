import cx from 'classnames';
import React, { FormEvent, ReactElement } from 'react';
import { TButtonStyleType, TButtonType } from '../../assets/ts/types';
import './index.scss';

interface IProps {
    type: TButtonType;
    styleType: TButtonStyleType;
    className?: string;
    disabled?: boolean;
    tip?: string;
    onClick?: (e: any) => void;
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
    children?: ReactElement | string;
}

const Button = ({ type, styleType, className, disabled, onClick, children, tip }: IProps) => {
    return (
        <button
            type={type}
            className={cx([
                className,
                'button',
                {
                    'button--primary': styleType === 'primary',
                    'button--secondary': styleType === 'secondary',
                    'button--icon': styleType === 'icon',
                    'button--icon-only': styleType === 'icon-only',
                    'button--text': styleType === 'text',
                },
            ])}
            title={tip}
            onClick={onClick}
            disabled={disabled}
        >
            {['primary', 'secondary'].includes(styleType) ? <span className="button-name">{children}</span>: children}
        </button>
    );
};

export default Button;
