import { createSlice } from '@reduxjs/toolkit';
import { IStateApp } from '../../assets/ts/types';

const initialState: IStateApp = {
    appsAvailable: [],
    currentApp: 'raskat',
    userSettings: null,
};

const appReducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppsAvailable(state, action) {
            return { ...state, appsAvailable: action.payload };
        },
        setCurrentApp(state, action) {
            return { ...state, currentApp: action.payload };
        },
        setUserSettings(state, action) {
            return { ...state, userSettings: action.payload };
        },
        setUserPageSettings(state, action) {
            const { page, settings } = action.payload;
            state.userSettings ??= { pages: {} };
            state.userSettings.pages ??= { [page]: {} };
            state.userSettings.pages[page] = settings;
        },
        setUserSettingsColumnSize(state, action) {
            const { page, column, size } = action.payload;
            const { columns } = state?.userSettings?.pages[page] || {};
            const targetColumnIndex = columns?.findIndex(col => col.name === column);

            if (columns && targetColumnIndex && targetColumnIndex > -1) {
                columns[targetColumnIndex].width = size;
            }
        },
        setUserSettingsColumnsOrder(state, action) {
            const { page, columnsOrder } = action.payload as { page: string; columnsOrder: string[] };
            let { columns } = state?.userSettings?.pages[page] || {};

            if (columns) {
                const newColumns = columns.sort((a, b) => columnsOrder.findIndex(i => i === a.name) - columnsOrder.findIndex(i => i === b.name));
                if (state.userSettings) {
                    state.userSettings.pages[page].columns = newColumns;
                }
            }
        },
        clearStorage() { },
    },
});

export const {
    setAppsAvailable,
    setCurrentApp,
    setUserSettings,
    setUserPageSettings,
    setUserSettingsColumnSize,
    setUserSettingsColumnsOrder,
    clearStorage,
} = appReducer.actions;
export default appReducer.reducer;
